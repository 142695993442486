import { Action } from '../../global/models';
import ActionType from '../actionTypes/emailTemplatesFilters.actionTypes';
import { DEFAULT_FILTER_OPTIONS } from '../emailTemplates/global/constants';

const INITIAL_STATE = DEFAULT_FILTER_OPTIONS;

export default (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case ActionType.SET_EMAIL_TEMPLATES_FILTERS:
            return action.payload;
        case ActionType.CLEAR_EMAIL_TEMPLATES_FILTERS:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};
