import { i18n } from '../../../global/constants.js';

export const BEM_BLOCK_NAME = 'email_templates';
export const BEM_BLOCK_NAME_EMAIL_TEMPLATE_PRIMARY_DETAILS = 'email_template_primary_details';
export const BEM_BLOCK_NAME_EMAIL_TEMPLATE_PRIMARY_DETAILS_EDIT_MODAL = 'email_template_primary_details_edit_modal';
export const BEM_BLOCK_NAME_EMAIL_TEMPLATE_OVERVIEW = 'email_template_overview';
export const BEM_BLOCK_NAME_NO_TEMPLATE_FOUND = 'no_template_found';
export const BEM_BLOCK_NAME_EMAIL_TEMPLATE_BUILDER = 'email_template_builder';
export const BEM_BLOCK_NAME_EMAIL_TEMPLATE_CREATE = 'email_template_create';
export const BEM_BLOCK_NAME_EMAIL_TEMPLATE_LIST_PAGE = 'email_template_list_page';

export const CREATE_EMAIL_TEMPLATE_ERROR_MESSAGE = 'Could Not Create Template!';
export const UPDATE_EMAIL_TEMPLATE_ERROR_MESSAGE = 'Could Not Update Template!';
export const MAX_FILE_SIZE = 26214400;

export const EMAIL_TEMPLATES_ROUTE_PATHS = {
    builder: 'email-template-builder',
    overview: 'overview',
    root: 'communications-central/email-templates',
};

export const DEFAULT_FILTER_OPTIONS = {
    churchEntityIds: null,
    sort: 'name,asc',
    isActive: null,
    isRealTemplate: null,
};

export const SORT_OPTIONS = [
    {
        label: i18n('Name (A > Z)'),
        value: 'name,asc',
    }, {
        label: i18n('Name (Z > A)'),
        value: 'name,desc',
    }, {
        label: i18n('Last Entry Date (Asc)'),
        value: 'lastentrydate,asc',
    }, {
        label: i18n('Last Entry Date (Desc)'),
        value: 'lastentrydate,desc',
    }, {
        label: i18n('Modify Date (Asc)'),
        value: 'updatedate,asc',
    }, {
        label: i18n('Modify Date (Desc)'),
        value: 'updatedate,desc',
    }, {
        label: i18n('Published (Asc)'),
        value: 'published,asc',
    }, {
        label: i18n('Published (Desc)'),
        value: 'published,desc',
    },
];
