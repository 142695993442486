import { Action } from '../../../global/models';
import ActionType from '../../actionTypes/emailTemplates.actionTypes';

export const INITIAL_STATE = {
    isFirstFetching: true,
    isLoading: false,
    communicationTemplates: [],
    searchValue: '',
    pageNumber: 0,
    count: 0,
};

export default (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case ActionType.SET_EMAIL_TEMPLATES_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case ActionType.SET_EMAIL_TEMPLATES:
            return {
                ...state,
                isLoading: false,
                isFirstFetching: false,
                communicationTemplates: [
                    ...state.communicationTemplates,
                    ...action.payload.communicationTemplates,
                ],
                pageNumber: state.pageNumber + 1,
                count: action.payload.count,
            };
        case ActionType.RESET_EMAIL_TEMPLATES:
            return {
                ...state,
                ...INITIAL_STATE,
                isFirstFetching: true,
                searchValue: state.searchValue,
            };
        case ActionType.SET_EMAIL_TEMPLATES_SEARCH_VALUE:
            return {
                ...state,
                searchValue: action.payload,
            };
        default:
            return state;
    }
};
